<template>
  <a-row :gutter="24" type="flex">
    <a-col :span="24" class="mb-24">
      <a-card
        :bordered="false"
        class="header-solid h-full"
        :bodyStyle="{ paddingTop: 0 }"
      >
        <template #title>
          <h5 class="font-semibold text-center">Add New Flow</h5>
        </template>
        <a-form
          id="components-form-demo-normal-login"
          :form="form"
          class="flow-upload-form"
          @submit="handleSubmit"
        >
          <a-row :gutter="24" type="flex">
            <a-col :span="24" :md="24" class="mb-24">
              <a-form-item class="mb-10">
                <a-input
                  v-decorator="[
                    'title',
                    {
                      rules: [
                        { required: true, message: 'Please input title!' },
                      ],
                    },
                  ]"
                  placeholder="Title"
                >
                </a-input>
              </a-form-item>
              <a-form-item class="mb-10" label="Flow Range">
                <a-range-picker
                  v-decorator="[
                    'range-picker',
                    {
                      rules: [
                        {
                          type: 'array',
                          required: true,
                          message: 'Please select start and end date!',
                        },
                      ],
                    },
                  ]"
                  :disabled-date="disabledDate"
                  @change="handleDateChange"
                />
              </a-form-item>
              <a-form-item class="mb-10">
                <div
                  style="
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    align-items: center;
                    gap: 5px;
                  "
                >
                  <div>
                    <label>Status: </label>
                    <a-switch
                      v-model:checked="status"
                      style="margin-left: 6px"
                    />
                  </div>
                </div>
              </a-form-item>
              <a-form-item v-for="(date, index) in dateRange" :key="index">
                <p>Feature for {{ moment(date).format("DD-MM-YYYY") }}</p>
                <a-row
                  v-for="(item, index) in featureData[date]"
                  type="flex"
                  justify="space-between"
                  :gutter="16"
                >
                  <a-col :span="16">
                    <a-select
                      placeholder="Select Feature"
                      show-search
                      :filter-option="filterOption"
                      @change="handleFeatureChange(date, index, $event)"
                    >
                      <a-select-option
                        v-for="(value, id) in featureList"
                        :key="value.id"
                      >
                        {{ value.name }} ({{ value.parent }})
                      </a-select-option>
                    </a-select>
                  </a-col>
                  <a-col :span="4">
                    <a-time-picker
                      :value="
                        item.time.length > 0 ? moment(item.time, 'HH:mm') : null
                      "
                      format="HH:mm"
                      @change="handleFeatureTimeChange($event, date, index)"
                    />
                  </a-col>
                  <a-col :span="4">
                    <a-row
                      v-if="featureData[date].length > 1"
                      type="flex"
                      justify="start"
                      align="middle"
                      style="height: 100%"
                    >
                      <a-col>
                        <a-button
                          type="danger"
                          @click="removeFeature(index, date)"
                          >Remove</a-button
                        >
                      </a-col>
                    </a-row>
                  </a-col>
                </a-row>
                <a-row type="flex" justify="start">
                  <a-col :span="2">
                    <a-button type="primary" @click="addFeature(date)"
                      >Add Feature</a-button
                    >
                  </a-col>
                </a-row>
              </a-form-item>
            </a-col>
          </a-row>
          <a-form-item>
            <a-button
              type="primary"
              block
              html-type="submit"
              class="login-form-button"
            >
              <a-spin v-if="confirmLoading" />
              Create
            </a-button>
          </a-form-item>
        </a-form>
      </a-card>
    </a-col>
  </a-row>
</template>

<script>
import { sendRequest } from "@/http/axios.method";
import event from "ant-design-vue/lib/_util/css-animation/Event";
import moment from "moment";

export default {
  name: "NewFlowUpload",
  computed: {
    event() {
      return event;
    },
  },
  data() {
    return {
      config: {
        Authorization: "Bearer " + this.$store.state.authUser.token,
      },
      status: false,
      confirmLoading: false,
      dateRange: [],
      featureData: [],
      featureList: [],
    };
  },
  beforeCreate() {
    // Creates the form and adds to it component's "form" property.
    this.form = this.$form.createForm(this, { name: "new_flow_upload" });
  },
  created() {
    this.getFeatureList();
  },
  methods: {
    moment,
    disabledDate(current) {
      // Can not select days before today and today
      return current && current < moment().endOf("day");
    },
    addFeature(date) {
      this.featureData[date].push({
        feature_id: "",
        time: "",
      });
      this.$forceUpdate();
    },
    removeFeature(index, date) {
      if (this.featureData[date].length > 1) {
        this.featureData[date].splice(index, 1);
        this.$forceUpdate();
      }
    },
    handleFeatureChange(date, index, value) {
      console.log(value);
      this.featureData[date][index].feature_id = value;
      this.$forceUpdate();
    },
    handleFeatureTimeChange(time, date, index) {
      this.featureData[date][index].time = time.format("HH:mm");
      this.$forceUpdate();
    },
    handleDateChange(dates) {
      const [start, end] = dates;
      const days = [];
      const startDate = start.clone();
      while (startDate.isSameOrBefore(end)) {
        days.push(startDate.format("YYYY-MM-DD"));
        this.featureData[startDate.format("YYYY-MM-DD")] = [
          {
            feature_id: "",
            time: "",
          },
        ];
        startDate.add(1, "day");
      }
      this.dateRange = days;
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.confirmLoading = true;
          let form_data = new FormData();
          let configHeader = {
            "content-type": "multipart/form-data",
            Authorization: "Bearer " + this.$store.state.authUser.token,
          };
          const dateRange = values["range-picker"];
          const startDate = dateRange[0];
          const endDate = dateRange[1];
          let data = {
            title: values.title,
            start_date: startDate.format("YYYY-MM-DD"),
            end_date: endDate.format("YYYY-MM-DD"),
            status: this.status ? 1 : 0,
          };
          for (let key in data) {
            form_data.append(key, data[key]);
          }
          while (startDate.isSameOrBefore(endDate)) {
            let sd = startDate.format("YYYY-MM-DD");
            this.featureData[sd].forEach((item, index) => {
              form_data.append(
                `features[${sd}][${index}][feature_id]`,
                item.feature_id
              );
              form_data.append(`features[${sd}][${index}][time]`, item.time);
            });
            startDate.add(1, "day");
          }
          sendRequest("post", "admin/flows", form_data, configHeader)
            .then((response) => {
              if (response.success) {
                this.confirmLoading = false;
                this.$store.commit("setMessage", response.message);
                this.$router.push("/flows");
              } else {
                if (response.status_code === 401) {
                  this.$store.dispatch("logout");
                  this.$router.push("/login");
                }
              }
            })
            .catch(() => {
              this.confirmLoading = false;
            })
            .finally(() => {
              this.confirmLoading = false;
            });
        }
      });
    },
    getFeatureList() {
      sendRequest("get", "admin/child_features", {}, this.config)
        .then((response) => {
          if (response.success) {
            let data = response.data;
            this.featureList = data.map(function (content, label) {
              return {
                id: content.id,
                name: content.name,
                parent: content.parent.name,
              };
            });
          } else {
            if (response.status_code === 401) {
              this.$store.dispatch("logout");
              this.$router.push("/login");
            }
          }
        })
        .catch(() => {})
        .finally(() => {});
    },
  },
};
</script>
